import ky, { Options } from 'ky';
import Vue from 'vue';
import { i18n } from '@/lang';
import { toast } from '@/lib';
import { dialogService } from './dialog';
import { commonService } from '@/services/common';
import { getStoredToken, setToken, userManager } from './oidc';

// let totalTabs: number;
// const tokenKey = 'user.token';
// const storage = localStorage;

// window.addEventListener('load', onWindowLoad);
// window.addEventListener('beforeunload', onWindowBeforeunload);
// export function onWindowLoad() {
//   const accessToken = localStorage.getItem(tokenKey)
//     ? localStorage.getItem(tokenKey)
//     : sessionStorage.getItem(tokenKey);
//   if (accessToken !== null) {
//     localStorage.setItem(tokenKey, accessToken);
//   }
//   if (!localStorage.getItem('count')) {
//     localStorage.removeItem(tokenKey);
//     localStorage.removeItem('count');
//     totalTabs = 0;
//   } else {
//     totalTabs = parseInt(localStorage.getItem('count'));
//   }
//   totalTabs++;
//   localStorage.setItem('count', '' + totalTabs);
// }
// export function onWindowBeforeunload() {
//   if (!localStorage.getItem('count')) {
//     totalTabs = 1;
//   } else {
//     totalTabs = parseInt(localStorage.getItem('count'));
//   }
//   totalTabs--;
//   localStorage.setItem('count', '' + totalTabs);
//   if (totalTabs < 1) {
//     sessionStorage.setItem(tokenKey, localStorage.getItem(tokenKey));
//     // localStorage.removeItem(tokenKey);
//   }
// }

// let alreadyHandling401 = false;

const options: Options = {
  prefixUrl: '/api/v1',
  timeout: 60000000,
  retry: 0,
  hooks: {
    beforeRequest: [
      function addHeaders({ headers }) {
        const token = getStoredToken();
        const locale = i18n.locale;
        if (token) headers.set('Authorization', `bearer ${token}`);
        if (locale) headers.set('Accept-Language', locale);
      },
    ],
    afterResponse: [
      async function redirectUnauthorized(_request, _options, response) {
        if (response.status === 401) {
          const token = localStorage.getItem("user.token");
          // localStorage.removeItem('user.token')
          if(token){
            await commonService?.logoutUser();
          }

          
          // throttle(
          //   function () {
          //     console.log('calling signOut');
          //     signOut();
          //   },
          //   1,
          //   { trailing: false, leading: true },
          // );
          // alreadyHandling401 = true;
          // await logout(true).finally(() => (alreadyHandling401 = false));
        }
      },
      async function handleEmptyBody(_request, _options, response) {
        if (response.status === 204) return;
        const body = await response.text();
        // TODO: server should send 204
        if (!body) return new Response('{}', { status: response.status ?? 500 });
      },
    ],
  },
};

export const client = ky.create(options);

type ShowErr = 'dialog' | 'toast' | 'raw' | boolean;
type Opts = Omit<Options, 'body' | 'json' | 'method'> & { showErr?: ShowErr };
// | 'prefixUrl'
function getErrorViewer(show: ShowErr): (name: string, message: string) => Promise<any> {
  switch (show) {
    case 'toast':
      return toast.error;
    case 'dialog':
      return (name: string, message: string) => dialogService.error(message || name);
    default:
      return (name: string, message: string) => Promise.resolve({ name, message });
  }
}

function showError(show: ShowErr = 'toast') {
  if (show === true) show = 'toast';
  const errorViewer = getErrorViewer(show);

  return async (e: Error) => {
    if (show === 'raw') throw e;
    e.name = e.name?.replace(/\s+/g, '');
    if (e.name == 'HTTPError' || (ky.HTTPError && (e instanceof ky.HTTPError))) {
      const { headers, status } = e.response;
      e.name = Math.floor(status / 100) === 5 ? 'serverError' : e.name ?? 'unkwownError';

      if (headers.get('Content-Type') === 'application/json') {
        const { message, result, resultData } = await e.response.json();
        e.message = message;
        (e as any).result = result ?? resultData;
      }
    }

    if (e.name == 'TimeoutError' || (ky.TimeoutError && (e instanceof ky.TimeoutError))) {
      e.name = 'timeoutError';
      e.message = ` پس از ${+options.timeout / 1000} ثانیه`;
    }

    errorViewer(e.name, e.message);
    throw e;
  };
}

function objToSearchParams(obj: any) {
  if (!obj) return;
  const p = new URLSearchParams();
  function recursive(val: any, path = '') {
    if (Array.isArray(val)) return val.filter((v) => v != null).forEach((v, i) => recursive(v, `${path}[${i}]`));
    if (typeof val !== 'object') return p.append(path, val);
    if (val instanceof Date) return p.append(path, val.toISOString());
    if (path) path += '.';
    Object.entries(val)
      .filter(([, v]) => v != null)
      .forEach(([k, v]) => recursive(v, path + k));
  }
  recursive(obj);
  return p;
}

export const httpService = {
  get<T = any>(url: string, searchParams?: any, { showErr, ...options }: Opts = {}) {
    const opts = { ...options, searchParams: objToSearchParams(searchParams) };
    return client.get(url, opts).json<T>().catch(showError(showErr));
  },
  delete<T = any>(url: string, searchParams?: any, { showErr, ...options }: Opts = {}) {
    const opts = { ...options, searchParams: objToSearchParams(searchParams) };
    return client.delete(url, opts).json<T>().catch(showError(showErr));
  },
  post<T = any>(url: string, json?: unknown, { showErr, ...options }: Opts = {}) {
    const opts = { ...options, json };
    return client.post(url, opts).json<T>().catch(showError(showErr));
  },
  put<T = any>(url: string, json: unknown, { showErr, ...options }: Opts = {}) {
    const opts = { ...options, json };
    return client.put(url, opts).json<T>().catch(showError(showErr));
  },
  patch<T = any>(url: string, json: unknown, { showErr, ...options }: Opts = {}) {
    const opts = { ...options, json };
    return client.patch(url, opts).json<T>().catch(showError(showErr));
  },
};

export const axiosCompatible = (() => {
  const addHandler = (e, timeout?: number) => {
    if (!e) throw e;
    e['handleAppException'] = async function (handler) {
      if (!e?.response) return handler();
      const { headers } = e.response;
      if (headers.get('Content-Type') !== 'application/json') return handler();
      const { message, succeeded = true, result, resultData } = await e.response.json().catch(() => ({}));
      if (succeeded == null) return handler();
      return handler(message, result ?? resultData);
    };
    if (ky['TimeoutError'] && (e instanceof ky['TimeoutError'])) {
      e.name = 'timeoutError';
      e.message = `پس از ${(timeout ? timeout : +options.timeout) / 1000} ثانیه`;
      toast.error('timeoutError', ` پس از ${(timeout ? timeout : +options.timeout) / 1000} ثانیه`);
    }
    throw e;
  };

  type AxiosRequestConfig = { params?: Options['searchParams'] };
  return {
    async get(url: string, { params }: AxiosRequestConfig = {}, timeout?: number) {
      url = url.replace(/^\/api\/v1\/?/, '');
      const searchParams = objToSearchParams(params);
      const data = await client
        .get(url, { searchParams, timeout: timeout ? timeout : options.timeout })
        .json<any>()
        .catch((err) => {
          addHandler(err, timeout);
        });
      return { data };
    },
    async delete(url: string, { params: searchParams }: AxiosRequestConfig = {}, timeout?: number) {
      url = url.replace(/^\/api\/v1\/?/, '');
      const data = await client
        .delete(url, { searchParams, timeout: timeout ? timeout : options.timeout })
        .json<any>()
        .catch((err) => {
          addHandler(err, timeout);
        });
      return { data };
    },
    async post(url: string, json: any, timeout?: number) {
      url = url.replace(/^\/api\/v1\/?/, '');
      const data = await client
        .post(url, { json, timeout: timeout ? timeout : options.timeout })
        .json<any>()
        .catch((err) => {
          addHandler(err, timeout);
        });
      return { data };
    },
    async put(url: string, json: any, timeout?: number) {
      url = url.replace(/^\/api\/v1\/?/, '');
      const data = await client
        .put(url, { json, timeout: timeout ? timeout : options.timeout })
        .json<any>()
        .catch((err) => {
          addHandler(err, timeout);
        });
      return { data };
    },
    async patch(url: string, json: any, timeout?: number) {
      url = url.replace(/^\/api\/v1\/?/, '');
      const data = await client
        .patch(url, { json, timeout: timeout ? timeout : options.timeout })
        .json<any>()
        .catch((err) => {
          addHandler(err, timeout);
        });
      return { data };
    },
  };
})();

export function HttpServicePlugin(V: typeof Vue) {
  V.prototype.$http = axiosCompatible;
}
