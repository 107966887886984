export default {
  computed: {
    isFa() {
      return this.$i18n.locale === 'fa';
    },
  },
  methods: {
    removeEmpty(obj) {
      return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null));
    },
    randomColor() {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    },
    toCurrency(price, options) {
      if (price === null || price === undefined || isNaN(price)) return ' - ';
      let amount = +price;
      return new Intl.NumberFormat(options?.locale ? options.locale : this.isFa ? 'fa-IR' : 'en-US',{
        minimumFractionDigits: 0,
        maximumFractionDigits: 20,
      }).format(amount);
    },
    toEnglishDigit(string) {
      if (string) {
        if (typeof string === 'number') {
          string = string.toString();
        }
        return string.toString().replace(/[\u0660-\u0669\u06F0-\u06F9]/g, function (event) {
          return event.charCodeAt(0) & 0xf;
        });
      }
    },
    justNumber(input) {
      if (!input) {
        return null;
      }
      const value = input.toString().match(/[0-9۰-۹]/g);
      if (value) {
        return +this.toEnglishDigit(value.join([]));
      }
      return typeof input === 'number' ? input : parseInt(input, 10);
    },
  },
};
